import React from 'react';
import {useWeb3React} from "@web3-react/core";

import {Wallet} from "../wallet/Wallet";
import Vectorscape from "../vectorscape/Vectorscape";
import "./Home.css";

const Home = () => {
    const {active} = useWeb3React();

    return (
        <div className={"homepage d-flex justify-content-center"}>
            <div className={"homepage-wallet"}>
                {active ? null : <Wallet/>}
            </div>
            <div className={"homepage-vectorscape"}>
                <Vectorscape name={"home-page"}/>
            </div>
        </div>
    );
}

export default Home;