import seedrandom from "seedrandom";

const createDistributionMatrix = (items) => {
    const arr = [];
    let count = 0;
    Object.keys(items).forEach((key) => {
        const value = items[key];
        for (let i = 0; i < value; i++) {
            arr.push(key);
            count++;
        }
    });

    return arr;
}

const getRandomInt = (max, seedPhrase) => {
    const seed = seedrandom(seedPhrase);
    return Math.floor(seed() * max);
}

const createRandomSelector = (items) => {
    const mat = createDistributionMatrix(items);
    return (seedPhrase) => {
        const randIndex = getRandomInt(mat.length, seedPhrase);
        return mat[randIndex];
    }
}

const layersDistribution = {3: 60, 4: 20, 5: 10, 6: 8, 7: 2};
const layerSelector = createRandomSelector(layersDistribution);

const sunRadiusDistribution = {10: 60, 16:20, 22: 10, 28: 8, 34: 2};
const radiusSelector = createRandomSelector(sunRadiusDistribution);

export {
    layerSelector,
    radiusSelector
}

