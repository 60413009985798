import React from 'react'
import {useWeb3React} from '@web3-react/core'
import {InjectedConnector} from '@web3-react/injected-connector'
import {Button} from "react-bootstrap";

const injectedConnector = new InjectedConnector({
    supportedChainIds: [
        1, // Mainet
        3, // Ropsten
        4, // Rinkeby
        5, // Goerli
        42, // Kovan,
        1337, // Localhost
    ],
})

export const Wallet = () => {
    const {account, activate, active} = useWeb3React();

    const onClick = () => {
        activate(injectedConnector)
    }

    return (
        <div id={"connect-wallet"}>
            {active ? (
                <div>{account[0]}</div>
            ) : (
                <div id={"connect-div"}>
                    <Button variant="outline-light" onClick={onClick}>
                        Connect
                    </Button>
                </div>
            )}
        </div>
    )
}