import React, {useEffect, useState} from 'react';
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import {useParams} from "react-router-dom";
import {withRouter} from "react-router";
import {layerSelector, radiusSelector} from "../../rand/randDistribution";
import Vectorscape from "../vectorscape/Vectorscape";

import "./Item.css";
import {Contract} from "../../contract/Contract";
import Alert from "react-bootstrap/Alert";


const Item = (props) => {
    const {tokenId} = useParams();
    console.log(props);
    const [name, setName] = useState('');
    const [err, setErr] = useState(null);

    useEffect(() => {
        let isMounted = true;
        async function loadItem() {
            const token = parseInt(tokenId);
            if (isNaN(token)) {
                setErr("Provided token is not valid.");
                return;
            }
            try {
                const vectorscape = await Contract.methods.getVectorscape(token).call();
                if (isMounted) {
                    setName(vectorscape.name);
                }
            } catch (e) {
                setErr(`Unable to load token ${name}`);
            }
        }

        loadItem().then(r => {
            return r;
        })

        return () => {
            isMounted = false;
        }
    }, [])

    const layerCount = layerSelector(`${name}-layers`);
    const radius = radiusSelector(`${name}-radius`);

    return (
        <div>
            {
                err === null ? (
                    name !== '' ? (
                        <div className="item-div">
                            <Card bg='secondary' text='white' style={{flex: 1, width: '60%', marginBottom: 5}}>
                                <Card.Header>{name}</Card.Header>
                                <Card.Body>
                                    <Vectorscape name={name}/>
                                    <ListGroup className="list-group-flush" variant={"secondary"}>
                                        <ListGroupItem variant="secondary">Layers: {layerCount}</ListGroupItem>
                                        <ListGroupItem variant="secondary">Radius: {radius}</ListGroupItem>
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </div>
                        ) :
                        <Alert variant='primary'>
                            Loading asset...
                        </Alert>
                ) : (
                    <Alert variant='danger'>
                        {err}
                    </Alert>
                )
            }
        </div>
    );
}

export default withRouter(Item);