import React, {useEffect, useState} from 'react';
import {useParams, useHistory} from "react-router-dom";
import {useWeb3React} from "@web3-react/core";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import Alert from "react-bootstrap/Alert";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";

import {Contract} from "../../contract/Contract";
import "./Gallery.css";
import Vectorscape from "../vectorscape/Vectorscape";
import {layerSelector, radiusSelector} from "../../rand/randDistribution";


const Gallery = () => {
    const [vectorscapes, setVectorscapes] = useState([])
    const {account, active} = useWeb3React();
    const params = useParams();
    const history = useHistory();

    const redirectToItem = async (name) => {
        try{
            const tokenId = await Contract.methods.getTokenId(name).call();
            history.push(`/item/${tokenId}`);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        let isMounted = true;
        async function buildGallery() {
            if (params.owner === undefined && !active) {
                history.push("/");
                return;
            }

            const address = params.owner === undefined ? account : params.owner;

            console.log('building gallery for account: ', address);
            try {
                if (isMounted) {
                    const ownerVectorscapes = await Contract.methods.getOwnerVectorscapes(address).call();
                    console.log(vectorscapes);
                    setVectorscapes((ownerVectorscapes));
                }
            } catch (e) {
                console.log(e);
            }
        }

        buildGallery().then(r => {
            return r;
        });

        return () => {
            isMounted = false;
        }
    }, [])

    return (
        <div id={'gallery-div'}>
            {
                vectorscapes.length > 0 ?
                    <CardDeck style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        {
                            vectorscapes.map((value, index) => {
                                const layerCount = layerSelector(`${value}-layers`)
                                const radius = radiusSelector(`${value}-radius`)
                                return (
                                    <Card bg='secondary' key={index} text='white'
                                          style={{flex: 1, minWidth: 400, maxWidth: 400, marginBottom: 5}}
                                          onClick={async () => {
                                              await redirectToItem(value.name)
                                          }}
                                    >
                                        <Card.Header>{value.name}</Card.Header>
                                        <Card.Body>
                                            <Vectorscape name={value.name}/>
                                            <ListGroup className="list-group-flush" variant={"secondary"}>
                                                <ListGroupItem
                                                    variant="secondary">Layers: {layerCount}</ListGroupItem>
                                                <ListGroupItem
                                                    variant="secondary">Radius: {radius}</ListGroupItem>
                                            </ListGroup>
                                        </Card.Body>
                                    </Card>
                                );
                            })
                        }
                    </CardDeck>
                    :
                    <div>
                        <Alert variant='primary'>
                            Looks like you don't have any Vectorscapes, check out the mint page or look for
                            deals on opensea!
                        </Alert>
                    </div>
            }
        </div>
    )
}

export default Gallery;