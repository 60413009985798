import React, {useEffect} from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {Link} from "react-router-dom";
import {useWeb3React} from '@web3-react/core'
import {Button} from "react-bootstrap";
import {InjectedConnector} from "@web3-react/injected-connector";

const Navigation = () => {
    const {account, activate, active} = useWeb3React();

    useEffect(() => {
        try {
            activate(injectedConnector);
        } catch (e) { } // do nothing, we aren't connected to web3 yet...
    }, [])

    const injectedConnector = new InjectedConnector({
        supportedChainIds: [
            1, // Mainet
            3, // Ropsten
            4, // Rinkeby
            5, // Goerli
            42, // Kovan,
            1337, // Localhost
        ],
    })

    const onClick = () => {
        activate(injectedConnector)
    }

    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand as={Link} to="/">Vectorscapes</Navbar.Brand>
            <Navbar.Collapse className="">
                <Nav className="mr-auto">
                    <Nav.Link as={Link} to="/mint">Mint</Nav.Link>
                    <Nav.Link as={Link} to="/gallery">Gallery</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            <Navbar.Toggle/>
            <Navbar.Collapse className="justify-content-end">
                {
                    active ? account :
                        <Button variant="outline-secondary" onClick={onClick}>
                            Connect
                        </Button>
                }
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Navigation;