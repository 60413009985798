import React, {useState} from 'react';
import {Redirect} from "react-router-dom";
import {useWeb3React} from "@web3-react/core";

import Vectorscape from "../vectorscape/Vectorscape";
import {Contract, web3} from "../../contract/Contract";

import "./Mint.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const defaultNameLength = 10;
const randomName = (length) => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result.toUpperCase();
}

const Mint = () => {
    const [names, setNames] = useState([randomName(defaultNameLength)]);
    const {account, active} = useWeb3React();
    const [trxId, setTrxId] = useState(null);
    const [mintConfirm, setMintConfirm] = useState(false);

    // handlers to close mint confirmation modals
    const handleClose = () => setMintConfirm(false);
    const handleShow = () => {
        setMintConfirm(true);
        setNames([randomName(defaultNameLength)]);
    }

    const mintVectorscape = async () => {
        // check that minting is not paused
        console.log('checking if contract is paused...');
        const paused = await Contract.methods.getPaused().call();
        if (paused) {
            alert('minting is currently paused!');
            return;
        }

        let price = await Contract.methods.getPrice().call();
        if (!price) {
            price = 0;
        } else {
            price = (parseFloat(web3.utils.fromWei(price.toString(), 'ether')) * names.length).toString();
        }

        // mint it!
        console.log('attempting to mint');
        Contract.methods.mint([...names]).send({
            from: account,
            value: web3.utils.toWei(price, 'ether')
        })
            .on('error', (err) => {
                console.log('error');
                console.log(err);
            })
            .on('transactionHash', (res) => {
                console.log('transactionHash', res);
                setTrxId(res);
                console.log(trxId);
                handleShow();
            });
    }

    const generateName = (i) => {
        const arr = [...names];
        arr[i] = randomName(defaultNameLength)
        setNames(arr);
    }

    const setName = (i, name) => {
        const arr = [...names];
        arr[i] = name;
        setNames(arr);
    }

    return (
        <>
            {
                active ?
                    <div id="mint-div" className="d-flex justify-content-center">
                        <div className={"mint-controls d-flex"}>
                            <button id="mint-btn" className="btn btn-outline-primary"
                                    onClick={async () => {
                                        await mintVectorscape();
                                    }}>Mint
                            </button>
                        </div>


                        <div id="mint-actions" className="form-group">
                            {
                                [...names.map((val, i) => {
                                    return (<div key={"mint-div" + i} className={"d-flex"}>
                                        <input key={"name-input" + i} type="text" className="form-control" value={val}
                                               onChange={(e) => {
                                                   setName(i, e.target.value.toUpperCase())
                                               }}/>
                                        <button id="mint-btn" className="btn btn-outline-secondary" onClick={() => {
                                            generateName(i);
                                        }}>Random
                                        </button>
                                    </div>)
                                })]
                            }
                        </div>
                        <div className={"mint-vectorscape"}>
                            <Vectorscape name={names[0]}/>
                        </div>
                        <Modal show={mintConfirm} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Submitted!</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Successfully submitted a transaction to mint.</p>
                                <p>
                                    <a href={"https://etherscan.io/tx/" + trxId}>Follow your transaction on etherscan
                                    </a>
                                </p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div> : <Redirect to={"/"}/>
            }
        </>
    );
}

export default Mint;