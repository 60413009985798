import React from 'react';
import Color from "color";
import seedrandom from 'seedrandom';

import "./Vectorscape.css";
import {layerSelector, radiusSelector} from "../../rand/randDistribution";

const Vectorscape = (props) => {
    const seedPhrase = props.name.toUpperCase();
    const cleanedSeedPhrase = seedPhrase.replace(" ", "_");
    const seed = seedrandom(seedPhrase);
    const offsetY = 200;
    const layerHeight = 150;
    const layerWidth = 500;
    const noiseHeight = 300;
    const minFreq = .006;

    const layers = layerSelector(`${seedPhrase}-layers`);
    const baseColor = Color("#45a8d6");
    const baseSkyColor = Color("#45a8d6");

    const sunXRng = seedrandom(`${seedPhrase}sunX`);
    const sunYRng = seedrandom(`${seedPhrase}sunY`);
    let sunX = sunXRng() * .5 + .25;
    let sunY = sunYRng() * .1 + .1;
    let radius = radiusSelector(`${seedPhrase}-radius`);

    let defs = [];
    let paths = [];

    for (let layer = 1; layer < layers + 1; layer++) {
        const anchorSeed = seedrandom(`${seedPhrase}anchor`);
        let layerAnchorY = layer * (layerHeight / layers) + offsetY + (anchorSeed() * (layerHeight / layers));
        let coord = `M 0 ${layerAnchorY} `;

        // back layers have higher peaks for more mountainous effect
        const amplitudeSeed = seedrandom(`${seedPhrase}amp`);
        const freq1Seed = seedrandom(`${seedPhrase}freq1`);
        const freq2Seed = seedrandom(`${seedPhrase}freq2`);
        const phase1Seed = seedrandom(`${seedPhrase}phase1`);
        const phase2Seed = seedrandom(`${seedPhrase}phase2`);
        let amplitude = (amplitudeSeed() * (layerHeight / layers)) + (layerHeight / layer / layers);
        let freq1 = (freq1Seed() * 0.02 * layer / layers) + minFreq;
        let freq2 = freq2Seed() * 0.01 + minFreq;
        let phase1 = phase1Seed() * layerWidth;
        let phase2 = phase1 + (phase2Seed() * layerWidth / 2);

        for (let i = 0; i < layerWidth; i++) {
            const detailsSeed = seedrandom(`${seedPhrase}details${i}${layer}`);
            const baseAndInterference =
                (Math.sin(freq1 * (i + phase1)) * amplitude) + // base
                (Math.sin(freq2 * (i + phase2)) * amplitude / 4);

            const coordSection = baseAndInterference + (Math.sin(layer) * Math.log10(layer * 200) / 2) + // detail
                (Math.sin((i + 3) * layer * detailsSeed() / 2) * (Math.log2(layer * 2) * 1.5)) + // more detail
                (layerAnchorY - layerHeight);


            coord += `H ${i} V ${coordSection} `;
        }

        const gradientRotation = seed.int32() % 60 + 60;


        coord += `V ${layerAnchorY} L 0 ${layerAnchorY}`;
        const gradientId = `fill${layer}${cleanedSeedPhrase}`

        defs.push(
            <radialGradient key={"def" + layer} id={gradientId} cx={sunX}
                            cy={(sunY / 2) - ((layer - 1) / Math.pow(layers, 2))}>
                <stop offset="0%"
                      stopColor={baseColor.rotate(gradientRotation).saturate(.1).lighten(.3).desaturate(.1 * layer).darken(.1 * layer).string()}/>
                <stop offset={(20 / layer) + "%"}
                      stopColor={baseColor.saturate(.1).desaturate((layer / layers) / 4).darken((layer / layers) / 2).rotate(gradientRotation - (gradientRotation * (layer / layers))).string()}/>
                <stop offset="100%"
                      stopColor={baseColor.saturate((layer / layers) / 2.5).darken((layer / layers)).string()}/>
            </radialGradient>
        );
        paths.push(<path key={"path" + layer} d={coord} fill={`url('#fill${layer}${cleanedSeedPhrase}')`}/>);
    }

    const noiseName = `noise${cleanedSeedPhrase}`;
    const bgName = `fillBg${cleanedSeedPhrase}`;
    const fogName = `fog${cleanedSeedPhrase}`;

    return (
        <svg id={`vectorscape-${props.name}`} className={"vectorscape-svg"} viewBox={`0 0 ${layerWidth} 250`}
             xmlns="http://www.w3.org/2000/svg">
            <defs>
                <radialGradient id={bgName} cx={sunX} cy={sunY}>
                    <stop offset="0" stopColor={baseSkyColor.rotate(180).desaturate(1).lighten(1).string()}/>
                    <stop offset="10%" stopColor={baseSkyColor.rotate(90).lighten(.7).string()}/>
                    <stop offset="20%" stopColor={baseSkyColor.rotate(45).desaturate(.5).lighten(.4).string()}/>
                    <stop offset="40%" stopColor={baseSkyColor.rotate(10).desaturate(.5).lighten(.2).string()}/>
                    <stop offset="80%" stopColor={baseSkyColor.rotate(5).desaturate(.5).darken(.1).string()}/>
                    <stop offset="100%" stopColor={baseSkyColor.rotate(3).desaturate(.5).darken(.2).string()}/>
                </radialGradient>
                {defs}
                <filter id={noiseName}>
                    <feTurbulence type="fractalNoise" baseFrequency="30" result="noisy"/>
                    <feColorMatrix type="saturate" values="0"/>
                    <feBlend in="SourceGraphic" in2="noisy" mode="multiply"/>
                </filter>
                <linearGradient id={fogName} x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="rgb(255,255,255)" stopOpacity={(1 - (sunY * 3))}/>
                    <stop offset="100%" stopColor="rgb(255,255,255)" stopOpacity="0"/>
                </linearGradient>
            </defs>
            <rect filter={`url(#${noiseName})`} x="0" y="0" width="100%" height={noiseHeight}
                  fill={`url(#${bgName})`}/>
            <circle cx={sunX * layerWidth} cy={sunY * noiseHeight} r={radius}
                    fill={baseColor.rotate(90).desaturate(.8).lighten(.8).string()}/>
            {paths.slice(0, 3)}
            <rect x="0" y="0" width="100%" height="100%" fill={"url(#" + fogName + ")"}/>
            {paths.slice(3)}
        </svg>
    );
}


export default Vectorscape;