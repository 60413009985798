import React, {Component} from 'react';
import {Route, Switch, BrowserRouter} from 'react-router-dom';
import {Web3ReactProvider} from '@web3-react/core'
import {Web3Provider} from '@ethersproject/providers'
import Container from "react-bootstrap/Container";

import Navigation from "./components/nav/Navigation";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Mint from "./components/mint/Mint";

import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Gallery from "./components/gallery/Gallery";
import Item from "./components/item/Item";

function getLibrary(provider) {
    const library = new Web3Provider(provider)
    library.pollingInterval = 12000
    return library
}

class App extends Component {
    render() {
        return (
            <Web3ReactProvider getLibrary={getLibrary}>
                <BrowserRouter>
                    <div className={"background-div"}>
                        <Navigation/>
                        <Container fluid>
                            <Switch>
                                <Route path="/" component={Home} exact/>
                                <Route path="/about" component={About}/>
                                <Route path="/mint" component={Mint}/>
                                <Route path="/gallery/:owner?" component={Gallery}/>
                                <Route path="/item/:tokenId" component={Item}/>
                            </Switch>
                        </Container>
                    </div>
                </BrowserRouter>
            </Web3ReactProvider>
        );
    }
}

export default App;
